<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle :title="title" slot="title" link="/helpContent/course"/>

    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-3" style="margin-top: -10px;">
          <lable style="font-size: 13px;    color: #555;">Course Name</lable>
           <!-- <FormSelect :item="courseCategoryList" item-name="Name" item-value="Id" v-model="form.courseCategory"  label="Course Name" rules="required" :readonly="readOnly" /> -->
           <FormSelect  v-model="form.courseCategory"  :multiple="true" :items="courseCategoryList" rules="required"  
            item-name="Name"
            item-value="Name" />
        </div>
        <div class="col-sm-3" >
           <inputText  v-model="form.courseName"  label="Course Name" rules="required"  />
        </div>
        <div class="col-sm-3" >
           <inputText  v-model="form.courseCode"  label="Course Code" rules="required"  />
        </div>
        <div class="col-sm-3" >
           <inputText  v-model="form.courseDuration"  label="Course Duration" rules="required"  />
        </div>
      </FormRow>
      
      <FormRow v-for="(item, index) in courseHeading" :key="index" >
        <div class="col-sm-4 formDiv">
          <label class="formLable">{{item.HeadingName}}</label>
        </div>
        <!-- <div class="col-sm-2 formDiv">
          <ButtonGroup>
            <FormButton type="success" @click="add(item)">Add</FormButton>
          </ButtonGroup>
        </div> -->
        <div class="col-sm-7"  @click="add(item)" v-html="item.Value" style="overflow-y: scroll;max-height: 220px;min-height:100px;    border: 2px solid #81c3ec;"></div>
      </FormRow>
      <!-- 
      <FormRow>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Who Should Enroll</label>
        </div>
        <div class="col-sm-3">
          <ButtonGroup>
            <FormButton type="success" @click="add(20)">Add</FormButton>
          </ButtonGroup>
        </div>
        <div class="col-sm-4" v-html="form.whoShouldEnroll"></div>
      </FormRow>
      <FormRow>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Prerequisites</label>
        </div>
        <div class="col-sm-3">
          <ButtonGroup>
            <FormButton type="success" @click="add(30)">Add</FormButton>
          </ButtonGroup>
        </div>
        <div class="col-sm-4" v-html="form.prerequisites"></div>
      </FormRow>
      <FormRow>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Learning Objectives</label>
        </div>
        <div class="col-sm-3">
          <ButtonGroup>
            <FormButton type="success" @click="add(40)">Add</FormButton>
          </ButtonGroup>
        </div>
        <div class="col-sm-4" v-html="form.learningObjectives"></div>
      </FormRow>
      <FormRow>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Key Modules</label>
        </div>
        <div class="col-sm-3">
          <ButtonGroup>
            <FormButton type="success" @click="add(50)">Add</FormButton>
          </ButtonGroup>
        </div>
        <div class="col-sm-4" v-html="form.keyModules"></div>
      </FormRow>

      <FormRow>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Benefits for Student</label>
        </div>
        <div class="col-sm-3">
          <ButtonGroup>
            <FormButton type="success" @click="add(60)">Add</FormButton>
          </ButtonGroup>
        </div>
        <div class="col-sm-4" v-html="form.benefitsForStudent"></div>
      </FormRow>
      <FormRow>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Faculty and Resources</label>
        </div>
        <div class="col-sm-3">
          <ButtonGroup>
            <FormButton type="success" @click="add(70)">Add</FormButton>
          </ButtonGroup>
        </div>
        <div class="col-sm-4" v-html="form.facultyAndResources"></div>
      </FormRow>
      <FormRow>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Support and Engagement</label>
        </div>
        <div class="col-sm-3">
          <ButtonGroup>
            <FormButton type="success" @click="add(80)">Add</FormButton>
          </ButtonGroup>
        </div>
        <div class="col-sm-4" v-html="form.supportAndEngagement"></div>
        
      </FormRow>
      <FormRow>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Certification</label>
        </div>
        <div class="col-sm-3">
          <ButtonGroup>
            <FormButton type="success" @click="add(90)">Add</FormButton>
          </ButtonGroup>
        </div>
        <div class="col-sm-4" v-html="form.certification"></div>
      </FormRow> -->


    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <FormButton
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (infoMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
    </Popup>
    <Popup width="50"
      :title="editorTitle"
      type="info"
      :value="editor.isVisible"
      @close="() => (editor.isVisible = false)"
      :closable="true"
    >
    <div v-for="(item,index) in courseHeading" :key="index">
      <EditorComponent v-if="editorId==item.Id"
        v-model="item.Value"
      />
    </div>
  

      <!-- <EditorComponent v-if="editorId===10"
        v-model="form.courseDescription"
        rules="required"
        label="Course Description"
      /> -->

      <!-- <EditorComponent v-else-if="editorId===20"
        v-model="form.whoShouldEnroll"
        rules="required"
        label="Who Should Enroll"
      /> -->
      <!-- <EditorComponent  v-else-if="editorId===30"
        v-model="form.prerequisites"
        rules="required"
        label="Prerequisites"
      />
      <EditorComponent v-else-if="editorId===40"
        v-model="form.learningObjectives"
        rules="required"
        label="Learning Objectives"
      />
      <EditorComponent v-else-if="editorId===50" v-model="form.keyModules" rules="required" label="Key Modules" />
      <EditorComponent v-else-if="editorId===60"
        v-model="form.benefitsForStudent"
        rules="required"
        label="Benefits for Student"
      />
      <EditorComponent v-else-if="editorId===70"
        v-model="form.facultyAndResources"
        rules="required"
        label="Faculty and Resources"
      />
      <EditorComponent v-else-if="editorId===80"
        v-model="form.supportAndEngagement"
        rules="required"
        label="Support and Engagement"
      />
      <EditorComponent v-else-if="editorId===90"
        v-model="form.certification"
        rules="required"
        label="Certification"
      /> -->
      <div slot="footer">
        <FormButton
          @click="
          () => (editor.isVisible = false) "
          >Save</FormButton
        >
      </div>
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { updateCourse, getCourseList,getCourseHeading,courseCategoryList } from "../api";
import { ValidationObserver } from "vee-validate";
import validateSelectedRows from "Mixins/validateSelectedRows";
export default {
  name: "add",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },
      editor: {
        isVisible: false,
      },
      filtering: {
      },
      sorting: {
        key: "id",
        order: "asc",
      },
      pagination: {
        perPage: 10,
        page: 1,
        total: 0,
      },
      form: {
        id: 0,
        courseDescription: "",
        whoShouldEnroll: "",
        prerequisites: "",
        learningObjectives: "",
        keyModules: "",
        benefitsForStudent: "",
        facultyAndResources: "",
        supportAndEngagement: "",
        certification: "",
        courseName:'',
        courseCode:'',
        courseDuration:'',
        courseCategory:[],
      },
      title: "",
      editorTitle:'Editor',
      editorId:0,
      readOnly: false,
      courseHeading:[],
      courseCategoryList:[]
    };
  },
  created() {
    this.getCourseCategory();
    if (this.$route.params.id) {
      this.showLoader();
      this.title = "Edit Course";
      this.form.id = atob(this.$route.params.id);
      this.form.termsAndConditionsAcceptance = true;
      this.getData();
    } else {
      this.title = "Add Course";
      this.getCourseHeading();
     
    }
  },
  methods: {
    add(e) {
      this.editorTitle=e.HeadingName;
      this.editorId=e.Id;
      this.editor.isVisible=true;

    },
    getCourseHeading(){
      getCourseHeading().then((res)=>{
        this.courseHeading=res.data
      });
    },
    getCourseCategory(){
      courseCategoryList().then((res)=>{
        this.courseCategoryList=res.data
      });
    },
   async getData() {
      const { key, order } = this.sorting;
      const { perPage, page } = this.pagination;

      const filters = {};
      filters.id = this.form.id;

      const data = {
        filterjson: {
          filter: [filters],
          sort: [{ key, order }],
          paging: [
            {
              startIndex: page,
              pagesize: perPage,
            },
          ],
        },
      };
      await getCourseList(data).then((res) => {
        this.form.courseName = res.data[0].ProgramName;
        this.form.courseCode = res.data[0].CourseCode;
        this.form.courseDuration = res.data[0].Duration;
        this.courseHeading=[];
        this.courseHeading=JSON.parse(res.data[0].CourseDetails);
        this.form.courseCategory= res.data[0].CourseCategories?res.data[0].CourseCategories.split(","):'';
        // this.form.courseDescription = res.data[0].Name;
        // this.form.whoShouldEnroll = res.data[0].whoShouldEnroll;
        // this.form.prerequisites = res.data[0].prerequisites;
        // this.form.learningObjectives = res.data[0].learningObjectives;
        // this.form.keyModules = res.data[0].keyModules;
        // this.form.benefitsForStudent = res.data[0].benefitsForStudent;
        // this.form.position = res.data[0].Position;
        // this.form.facultyAndResources = res.data[0].facultyAndResources;
        // this.form.supportAndEngagement = res.data[0].supportAndEngagement;
        // this.form.certification = res.data[0].certification;

      });
      this.hideLoader();
    },
    // send data to server
    sendData() {

      this.$refs.validator.validate().then((result) => {
        if (result) {
          this.termsAndConditionsAcceptance = false;
          this.showMessage.isVisible = false;
          this.infoMessage.isVisible = false;
          const data = {
            id: this.form.id == 0 ? 0 : parseInt(this.form.id),
            // courseDescription: this.form.courseDescription,
            // whoShouldEnroll: this.form.whoShouldEnroll,
            // prerequisites: this.form.prerequisites,
            // learningObjectives: this.form.learningObjectives,
            // keyModules: this.form.keyModules,
            // benefitsForStudent: this.form.benefitsForStudent,
            // facultyAndResources: this.form.facultyAndResources,
            // supportAndEngagement: this.form.supportAndEngagement,
            // certification: this.form.certification,
            courseJson:JSON.stringify(this.courseHeading),
            courseName: this.form.courseName,
            courseCode: this.form.courseCode,
            courseDuration: this.form.courseDuration,
            courseCategory: this.form.courseCategory.join(","),
          };
          updateCourse(data).then((res) => {
            if (res.data[0].status == 1) {
              this.form = {};
              this.showMessage.isVisible = true;
              this.showMessage.message = res.data[0].message;
            } else {
              this.infoMessage.isVisible = true;
              this.infoMessage.message = res.data[0].message;
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.error {
  color: #ff3547;
  position:absolute;
  left: 0;
  font-size: 11px;
  line-height: 13px;
  top: calc(100% + 2px);
  font-weight: 400;
}
.formDiv {
  padding-top: 30px;
}
.formLable {
  color: #515a6e;
  font-size: 15px;
}
.label {
  display: flex;
}
</style>
